import { attachDynamicEvent } from '~/common/helpers/dom'
import './index.scss'

const destroyAllEmbeds = () => {
  document
    .querySelectorAll('.simplero-embedded-order-form')
    ?.forEach((node) => node.remove())
  document.body.classList.remove('embedded-order-form-open')
}
const createEmbed = (purchaseUrl, classes) => {
  destroyAllEmbeds()

  const html = `
    <div class="simplero-embedded-order-form">
      <div class="simplero-embedded-order-form__iframe__container ${classes}">
        <iframe src="${purchaseUrl}"></iframe>
        <div class="simplero-embedded-order-form__loader__container">
          <div class="simple-loader"></div>
        </div>
      </div>
    </div>
  `
  document.body.insertAdjacentHTML('beforeend', html)

  const embed = document.querySelector('.simplero-embedded-order-form')
  embed.querySelector('iframe').addEventListener('load', () => {
    embed
      .querySelector('.simplero-embedded-order-form__loader__container')
      .remove()
  })
  requestAnimationFrame(() => {
    embed.classList.add('animate-in')
  })
  document.body.classList.add('embedded-order-form-open')
}

// Close the popup when backdrop is clicked
attachDynamicEvent('.simplero-embedded-order-form', 'click', (e) => {
  if (!e.target.closest('iframe')) {
    destroyAllEmbeds()
  }
})

export const openOrderFormInEmbed = (e, selector, classes) => {
  e.preventDefault()

  const target = e.target.closest(selector)

  const url = new URL(target.href)
  url.host = window.location.host
  createEmbed(url.toString(), classes)
}

const selector = '[href*=purchase][href*=simplero_embedded_order_form]'

const onOpenEmbeddedFormClick = (e) => {
  openOrderFormInEmbed(e, selector, 'one-click-container')
}

// When a embedded orderform link is pressed, open order form in an iframe embed
attachDynamicEvent(selector, 'click', onOpenEmbeddedFormClick)
