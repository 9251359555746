import { attachDynamicEvent } from '~/common/helpers/dom'
import { openOrderFormInEmbed } from '../embedded_order_form'

/*
  Attemt to do a 1-click purchase:
  1. If we have the payment method:
      If we are in a funnel & the last purchase was less than 12 hours ago
        Charge the payment method immediately
      Else
        Show a popup confirming the charge amount & accepting terms.
  2. If we don't have the payment method:
      Show an embedded popup order form
*/

const selector = '[href*=purchase][href*=simplero_one_click_purchase]'

const onOneClickPurchaseClick = (e) => {
  openOrderFormInEmbed(e, selector, 'one-click-container')
}

attachDynamicEvent(selector, 'click', onOneClickPurchaseClick)
